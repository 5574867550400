import React from 'react';
import Buttons from './Buttons';
import RemoteVideo from './RemoteVideo';
import RemoteVideoPlaceholder from './RemoteVideoPlaceholder';
import Chat from './Chat';
import Next from '@material-ui/icons/SkipNext';
import FlipCameraIosIcon from '@material-ui/icons/FlipCameraIos';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeOnIcon from '@material-ui/icons/VolumeUp';
import Icon from '@mdi/react';
import { mdiGenderMale } from '@mdi/js'
import { mdiGenderFemale } from '@mdi/js'
import { mdiGenderTransgender } from '@mdi/js'
import { mdiAccountMultiple } from '@mdi/js';

import './../css/Main.css';

export default class Main extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      client: this.props.client,
      start: false,
      toId: '',
      initiator: null,
      disconnected: false,
      localVideo: null,
      genderSelected: 'f',
      genderFiltered: 'any',
      remoteGender: null,
      currentVideoDeviceId: null,
      location: null,
      remoteLocation: null
    };

    this.onConnected = this.onConnected.bind(this);
    this.onStrangerDisconnect = this.onStrangerDisconnect.bind(this);

    this.stream = null;
    this.localVideo = React.createRef();
    this.remoteVideo = React.createRef();

    this.videoDeviceIds = [];

    this.micMuted = false;
  }

  componentDidMount() {
    this.state.client.registerCommonHandler(this.onConnected, this.onStrangerDisconnect);
    this.getStream();
  }

  componentDidUpdate() { }

  componentWillUnmount() {
    this.state.client.unRegisterCommonHandler();
  }

  onConnected({ toId, initiator, gender, location }) {
    this.setState({ toId, initiator, remoteGender: gender, remoteLocation: location });
    this.renderGender();
    this.renderLocation();
  }

  onStrangerDisconnect() {
    this.setState({ toId: '', initiator: null, disconnected: true, remoteGender: null, remoteLocation: null });
    this.renderGender();
    this.renderLocation();
  }

  renderGender() {
    let element = '';
    document.querySelectorAll('.remoteGenderLabel').forEach(function(element){
      element.style.display = 'none';
    });
    if (this.state.remoteGender) {
      switch (this.state.remoteGender) {
        case "m":
          element = 'genderMale';
          break;
        case "f":
          element = 'genderFemale';
          break;
        case "l":
          element = 'genderLGBT';
          break;
        case "c":
          element = 'genderCouple';
          break;
        case "g":
          element = 'genderGroup';
          break;
        default:
          break;
      }
      document.getElementById(element).style.display = 'block';
    }
  }

  renderLocation() {
    if (this.state.remoteLocation) {
    }
  }

  onToggleSound() {
    let remoteVideo = document.querySelector('.video.remote video');
    let volumeOn = document.getElementById('volumeOn');
    let volumeOff = document.getElementById('volumeOff');
    if (remoteVideo) {
      if (remoteVideo.muted) {
        remoteVideo.muted = false;
        volumeOn.style.display = 'none';
        volumeOff.style.display = 'block';
      } else if (!remoteVideo.muted) {
        remoteVideo.muted = true;
        volumeOn.style.display = 'block';
        volumeOff.style.display = 'none';
      }
    }
  }

  onToggleMic() {
    let micOnIcon = document.getElementById('micOn');
    let micOffIcon = document.getElementById('micOff');
    const isMuted = this.micMuted;
    // let that = this;
    if (!isMuted) {
      micOnIcon.style.display = 'block';
      micOffIcon.style.display = 'none';
      this.micMuted = true;
    } else {
      micOnIcon.style.display = 'none';
      micOffIcon.style.display = 'block';
      this.micMuted = false;
    }
    if (this.remoteVideo.current) {
      this.remoteVideo.current.peer.streams.forEach((mediaStream) => {
        // const stream = mediaStream;
        mediaStream.getAudioTracks().forEach((track) => {
          // if (track.kind !== 'audio') {
          //   return;
          // }
          // let newTrack = track;
          if (track.muted && isMuted) {
            track.enabled = true; // unmute
          } else if (!track.muted && !isMuted) {
            track.enabled = false; // mute
          }
          // that.remoteVideo.current.peer.replaceTrack(track, newTrack, stream);
        });
      });
    }
  }

  clickNext() {
    this.setState({ toId: '', initiator: null, disconnected: false, remoteGender: null });
    if (this.state.genderSelected) {
      this.state.client.startSearch(this.state.toId, this.state.genderSelected, this.state.genderFiltered); // skip remote id
      this.renderGender();
    } else {
      alert('Please identify your gender first');
    }
  }

  toogleStart() {
    if (this.state.start) {
      this.state.client.stop(this.state.toId);
      this.setState({ start: false, toId: '', initiator: null, disconnected: false, remoteGender: null });
      this.renderGender();
    } else {
      if (this.state.genderSelected) {
        this.state.client.startSearch(null, this.state.genderSelected, this.state.genderFiltered);
        this.setState({ start: true });
      } else {
        alert('Please identify your gender first');
      }
    }
  }

  toggleGenderSelect(e) {
    this.setState({ genderSelected: e.target.value });
  }

  toggleGenderFilter(e) {
    this.setState({ genderFiltered: e.target.value });
  }

  handleError = error => {
    console.error('Error: '+ error);
  }

  gotMedia = (stream) => {
    this.setState({ localVideo: true, currentVideoDeviceId: (stream.getVideoTracks()[0].getSettings().deviceId ?? null) });
    this.stream = stream;
    if ('srcObject' in this.localVideo.current) {
      this.localVideo.current.srcObject = stream;
    } else {
      this.localVideo.current.src = window.URL.createObjectURL(stream);
    }
  };

  getStream() {
    let userMedia =
      navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;

    if (typeof navigator.mediaDevices.getUserMedia === 'undefined') {
      userMedia({ video: true, audio: true })
        .then(this.gotMedia)
        .catch(this.handleError);
    } else {
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: true })
        .then(this.gotMedia)
        .catch(this.handleError);

      let that = this;
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        devices.forEach((device) => {
          if (device.kind === 'videoinput') {
            that.videoDeviceIds.push(device.deviceId);
          }
        });
      }).catch(this.handleError);
    }
  }

  async switchCamera() {
    let deviceId;
    if (this.videoDeviceIds.length > 1) {
      let key = this.state.currentVideoDeviceId ? this.videoDeviceIds.indexOf(this.state.currentVideoDeviceId) : 0;
      
      if (this.videoDeviceIds[(key + 1)]) {
        deviceId = this.videoDeviceIds[(key + 1)];
      } else {
        deviceId = this.videoDeviceIds[0];
      }
      this.setState({ currentVideoDeviceId: deviceId });
      let prevStream = this.stream;
      await navigator.mediaDevices.getUserMedia({ video: {deviceId: deviceId} }).then(this.gotMedia).catch(this.handleError);
      this.changeVideoStream(prevStream, this.stream);
    }
  }

  changeVideoStream(prevStream, stream)
  {
    if (this.remoteVideo.current) {
      this.remoteVideo.current.peer.removeStream(prevStream);
      this.remoteVideo.current.peer.addStream(stream);
    }
  }

  render() {
    return (
      <div className="main">
        <div className="video-area">
          <div className="video remote">
            {this.state.toId ? (
              <RemoteVideo
                ref={this.remoteVideo}
                client={this.state.client}
                toId={this.state.toId}
                initiator={this.state.initiator}
                stream={this.stream}
              />
            ) : (
                <RemoteVideoPlaceholder
                  registerOnlineUsers={this.state.client.registerOnlineUsers}
                  getOnlineUsers={this.state.client.getOnlineUsers}
                  searching={!this.state.toId && this.state.start}
                  disconnected={this.state.disconnected}
                />
              )}
            
            <div id="remoteSettings">
              {(this.state.toId || this.state.disconnected) && (
                <div className="remoteSettingsBtn">
                  <button className="next" onClick={() => this.clickNext()}>
                    <Next />
                  </button>
                  <button className="toggleSound" onClick={() => this.onToggleSound()}>
                    <span id="volumeOff"><VolumeOffIcon /></span>
                    <span id="volumeOn"><VolumeOnIcon /></span>
                  </button>
                </div>
              )}
              <div class="remoteGenderLabel" id="genderMale" title="Male"><Icon path={mdiGenderMale} size={1} /></div>
              <div class="remoteGenderLabel" id="genderFemale" title="Female"><Icon path={mdiGenderFemale} size={1} /></div>
              <div class="remoteGenderLabel" id="genderLGBT" title="LGBT+"><Icon path={mdiGenderTransgender} size={1} /></div>
              <div class="remoteGenderLabel" id="genderCouple" title="Couple"><Icon path={mdiAccountMultiple} size={1} /></div>
              <div class="remoteGenderLabel" id="genderGroup" title="Group"><Icon path={mdiAccountMultiple} size={1} /></div>
            </div>
          </div>
          <div id="local" className="video local">
            <div
              style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                color: '#fafafa'
              }}
            >
              {this.state.localVideo ? (
                <video ref={this.localVideo} autoPlay muted playsInline></video>                
              ) : (
                  <div>
                    <h1>
                      we can't get your camera{' '}
                      <span role="img" aria-label="surprised">
                        &#128546;
                    </span>
                    </h1>
                    <h3>
                      but you can still use this app{' '}
                      <span role="img" aria-label="surprised">
                        &#128527;
                    </span>
                    </h3>
                    <h3>or try again to give us access to your camera</h3>
                  </div>
                )}
            </div>
            <div id="localSettings">
              <button className="toggleMic" onClick={() => this.onToggleMic()}>
                <span id="micOff"><MicOffIcon /></span>
                <span id="micOn"><MicIcon /></span>
              </button>
              <button onClick={() => this.switchCamera()}>
                <FlipCameraIosIcon />
              </button>
            </div>
          </div>
        </div>
        <div className="bottom-area">
          <Buttons
            searching={!this.state.toId && this.state.start}
            connected={this.state.toId ? true : false}
            disconnected={this.state.disconnected}
            start={() => this.toogleStart()}
            genderSelect={(e) => this.toggleGenderSelect(e)}
            genderFilter={(e) => this.toggleGenderFilter(e)}
            genderSelected={this.state.genderSelected}
            genderFiltered={this.state.genderFiltered}
          />
          <Chat client={this.state.client} />
        </div>
        <div className="footer-area">
          <ul>
            <li><a href="/blog.html">Blog</a></li>
            <li><a href="mailto:webcum.fun@gmail.com">Contact us</a></li>
            <li><a href="https://paypal.me/webcum" target="_blank" title="Help us keep this website free! Donate now on paypal" rel="noopener noreferrer">Donate</a></li>
            <li><a href="https://x.com/webcumfun" target="_blank" rel="noopener noreferrer">X</a></li>
            <li><a href="https://instagram.com/webcum.fun" target="_blank" rel="noopener noreferrer">Instagram</a></li>
            <li><a href="/omegle" title="Free Omegle Alternative">Omegle</a></li>
            <li><a href="/livecams" title="Free Live Webcams" target="_blank">Live Cams</a></li>
            <li>This is <a href="https://webcum.fun">webcum.fun</a></li>
          </ul>
        </div>
      </div>
    );
  }
}
