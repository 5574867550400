import React, { Component } from 'react';

import './../css/Buttons.css';

export default class Buttons extends Component {
  render() {
    return (
      <div className="left">
        <div className="buttons">
          <button
            className="start"
            style={{ backgroundColor: this.props.searching || this.props.connected ? '#fd4545' : '#51fd6d' }}
            onClick={this.props.start}
          >
            {this.props.searching || this.props.connected ? 'Stop' : 'Start'}
          </button>
          <span>I'm</span>
          <select
            className="genderSelect"
            value={this.props.genderSelected} 
            onChange={this.props.genderSelect}
          >
            <option value="c">Couple</option>
            <option value="g">Group</option>
            <option value="f" selected>Female</option>
            <option value="lgbt">LGBT+</option>
            <option value="m">Male</option>
          </select>
          <span>Search for</span>
          <select
            className="genderFilter"
            value={this.props.genderFiltered} 
            onChange={this.props.genderFilter}
          >
            <option value="any">Any</option>
            <option value="c">Couple</option>
            <option value="g">Group</option>
            <option value="f">Female</option>
            <option value="lgbt">LGBT+</option>
            <option value="m">Male</option>
          </select>
        </div>
      </div>
    );
  }
}
